import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Employee = ({ name, image, icon }) => {
  const data = useStaticQuery(graphql`
    fragment employeeImage on File {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          breakpoints: [400]
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    query {
      andreas: file(relativePath: { eq: "employees/andreas.jpg" }) {
        ...employeeImage
      }
      boudewijn: file(relativePath: { eq: "employees/boudewijn.jpg" }) {
        ...employeeImage
      }
      floris: file(relativePath: { eq: "employees/floris.jpg" }) {
        ...employeeImage
      }
      henk: file(relativePath: { eq: "employees/henk.jpg" }) {
        ...employeeImage
      }
      jesper: file(relativePath: { eq: "employees/jesper.jpg" }) {
        ...employeeImage
      }
      marees: file(relativePath: { eq: "employees/marees.jpg" }) {
        ...employeeImage
      }
      robin: file(relativePath: { eq: "employees/robin.jpg" }) {
        ...employeeImage
      }
      ramon: file(relativePath: { eq: "employees/ramon.jpg" }) {
        ...employeeImage
      }
      robbin: file(relativePath: { eq: "employees/robbin.jpg" }) {
        ...employeeImage
      }
      sietze: file(relativePath: { eq: "employees/sietze.jpg" }) {
        ...employeeImage
      }
      sonny: file(relativePath: { eq: "employees/sonny.jpg" }) {
        ...employeeImage
      }
      stefan: file(relativePath: { eq: "employees/stefan.jpg" }) {
        ...employeeImage
      }
      sven: file(relativePath: { eq: "employees/sven.jpg" }) {
        ...employeeImage
      }
      tom: file(relativePath: { eq: "employees/tom.jpg" }) {
        ...employeeImage
      }
      tristan: file(relativePath: { eq: "employees/tristan.jpg" }) {
        ...employeeImage
      }
      marty: file(relativePath: { eq: "employees/marty.jpg" }) {
        ...employeeImage
      }
      eelco: file(relativePath: { eq: "employees/eelco.jpg" }) {
        ...employeeImage
      }
      patrick: file(relativePath: { eq: "employees/patrick.jpg" }) {
        ...employeeImage
      }
      michel: file(relativePath: { eq: "employees/michel.jpg" }) {
        ...employeeImage
      }
      len: file(relativePath: { eq: "employees/len.jpg" }) {
        ...employeeImage
      }
      glenn: file(relativePath: { eq: "employees/glenn.jpg" }) {
        ...employeeImage
      }
      brian: file(relativePath: { eq: "employees/brian.jpg" }) {
        ...employeeImage
      }
      bart: file(relativePath: { eq: "employees/bart.jpg" }) {
        ...employeeImage
      }
      peter: file(relativePath: { eq: "employees/peter.jpg" }) {
        ...employeeImage
      }
      roline: file(relativePath: { eq: "employees/roline.jpg" }) {
        ...employeeImage
      }
      yannick: file(relativePath: { eq: "employees/yannick.jpg" }) {
        ...employeeImage
      }
      jari: file(relativePath: { eq: "employees/jari.jpg" }) {
        ...employeeImage
      }
      joris: file(relativePath: { eq: "employees/joris.jpg" }) {
        ...employeeImage
      }
      jake: file(relativePath: { eq: "employees/jake.jpg" }) {
        ...employeeImage
      }
      lorenzo: file(relativePath: { eq: "employees/lorenzo.jpg" }) {
        ...employeeImage
      }
      jasper: file(relativePath: { eq: "employees/jasper.jpg" }) {
        ...employeeImage
      }
    }
  `);

  return (
    <div className="employee">
      <GatsbyImage image={getImage(image ? data[image] : data[name])} alt={name} />
      <h4>
        {name}
        {icon !== false ? ` ${icon}` : null}
      </h4>
    </div>
  );
};

Employee.defaultProps = {
  image: false,
};

Employee.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  name: PropTypes.string.isRequired,
};

export default Employee;
